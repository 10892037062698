import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../common/styles/theme/theme.style";
import SEO from "../EpMain/Seo/SEO";
import Ep404 from "../Ep-404/Ep404/Ep404";
import Layout from "../common/Layout/Layout";
import { CssBaseline } from "@material-ui/core";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <CssBaseline />
      <Layout>
        <Ep404 />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
