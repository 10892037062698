import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  img: {
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "200px",
    },
  },
}));

export const UnknownSpaceIllustration = (): JSX.Element => {
  return (
    <StaticImage
      src="../../../assets/images/UnknownSpaceIllustration.svg"
      width={1100}
      quality={95}
      placeholder="tracedSVG"
      formats={["auto", "webp", "avif"]}
      alt="404 Image"
      className={useStyles().img}
    />
  );
};
