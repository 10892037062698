import React from "react";
import { Grid, Typography } from "@material-ui/core";
import HeadingDash from "../../common/components/HeadingDash/HeadingDash";
import { useStyles } from "./Ep404.styles";
import { UnknownSpaceIllustration } from "../../common/images/illustrations/UnknownSpaceIllustration";

const Ep404 = (): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.header}>
            <HeadingDash justify="center" />
            This Page does not exist
          </Typography>
          <Typography variant="body2" className={classes.description}>
            You have ventured into unknown space
          </Typography>
        </Grid>
        <Grid
          item
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          className={classes.imgWrapper}
        >
          <UnknownSpaceIllustration />
        </Grid>
      </Grid>
    </section>
  );
};

export default Ep404;
